export function directions(Alpine) {
  Alpine.data('directions', function() {
    return {
      tab: this.$persist('railway'),
      railway: this.$persist(null),
      airport: this.$persist(null),

      init() {},
    };
  });
}
