import { ClickedOutside } from '../../lib/ClickedOutside';
import { xl } from '../../common/screen';

export class TopSearch {
  private readonly menu: HTMLElement;
  private readonly button: HTMLElement;
  private readonly closeButton: HTMLElement;
  private clickedOutside: ClickedOutside;

  constructor() {
    this.menu = document.querySelector('.header__search-field');
    this.button = document.querySelector('.header__search');
    if (!this.menu || !this.button) return;

    this.button.addEventListener('click', this.toggle);

    this.clickedOutside = new ClickedOutside(this.menu, this.close);

    window
      .matchMedia(`(min-width: ${xl}px)`)
      .addEventListener('change', this.handleScreenChangeToDesktop);
  }

  handleScreenChangeToDesktop = e => {
    if (e.matches && this.isOpened()) {
      this.close();
    }
  };

  handleEscapePress = e => {
    if (e.key === 'Escape') {
      this.close();
    }
  };

  open = () => {
    this.button.setAttribute('aria-expanded', 'true');
    this.menu.classList.add('is-opened');
    window.addEventListener('keydown', this.handleEscapePress);
    this.clickedOutside.start();
  };

  close = () => {
    this.button.setAttribute('aria-expanded', 'false');
    this.menu.classList.remove('is-opened');
    window.removeEventListener('keydown', this.handleEscapePress);
    this.clickedOutside.stop();
  };

  isOpened = () => {
    return this.button.getAttribute('aria-expanded') === 'true';
  };

  toggle = () => {
    console.log('toggle');
    if (!this.isOpened()) this.open();
    else this.close();
  };
}
