type CatalogProperties = {
  SECTION_ID: number;
  SECTION_CODE: string;
  SECTION_URL: string;
  DETAIL_URL: string;
  COMPARE_PATH: string;
  PAGEN_1: string;
};

export function catalog(Alpine) {
  Alpine.data('catalog', (params: CatalogProperties) => ({
    page: parseInt(params.PAGEN_1.split('-')[1] ?? '1', 10),
    totalPages: 0,
    isLoading: false,
    get isLastPage() {
      return this.page === this.totalPages;
    },
    init() {
      if (!this.$refs.loadMoreWrap) return;

      const paginationItems = this.$root.querySelectorAll('.pagination__item:not(.is-next)');
      const lastPageLink = paginationItems[paginationItems.length - 1];
      if (lastPageLink) {
        this.totalPages = parseInt(lastPageLink.innerText.trim(), 10)
      }
    },
    loadMore() {
      this.page++;
      this.isLoading = true;
      $.ajax({
        type: 'GET',
        url: '/instrument/section-ajax.php',
        data: {
          SECTION_ID: params.SECTION_ID,
          SECTION_CODE: params.SECTION_CODE,
          SECTION_URL: params.SECTION_URL,
          DETAIL_URL: params.DETAIL_URL,
          COMPARE_PATH: params.COMPARE_PATH,
          PAGEN_1: `page-${this.page}`,
        },
        success: data => {
          const processed = BX.processHTML(data);
          this.$refs.loadMoreWrap.insertAdjacentHTML('beforebegin', processed.HTML);
          BX.ajax.processScripts(processed.SCRIPT);
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
    },
  }));
}
