import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { xl } from '../../common/screen';

export function infoCards() {
  new Swiper('.info-cards.is-3-cards .info-cards__swiper', {
    modules: [Pagination],
    direction: 'horizontal',
    freeMode: true,
    forceToAxis: true,
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      [xl]: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  new Swiper('.info-cards.is-4-cards .info-cards__swiper', {
    modules: [Pagination],
    direction: 'horizontal',
    freeMode: true,
    forceToAxis: true,
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      [xl]: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });
}
