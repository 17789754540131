function handleOpen(detail: HTMLDetailsElement, selector: string) {
	if (!detail.open) return;

	const siblings = detail.parentNode?.querySelectorAll(selector);
	if (siblings) {
		siblings.forEach(sibling => {
			if (sibling === detail) return;
			sibling.removeAttribute("open");
		});
	}
}

export function detailsGroup(selector: string) {
	const details = document.querySelectorAll(selector);
	details.forEach(detail =>
		detail.addEventListener("toggle", event =>
			handleOpen(event.target as HTMLDetailsElement, selector)
		)
	);
}

