export type MetrikaFunctions =
  | 'addFileExtension'
  | 'extLink'
  | 'file'
  | 'firstPartyParams'
  | 'firstPartyParamsHashed'
  | 'getClientID'
  | 'hit'
  | 'notBounce'
  | 'params'
  | 'reachGoal'
  | 'setUserID'
  | 'userParams';

export type ReachGoalParams = {
  order_price?: number;
  currency?: string;
};

export class YandexMetrika {
  id: number;

  constructor(id: number) {
    this.id = id;
  }

  ym(func: MetrikaFunctions, ...args) {
    return window.ym(this.id, func, ...args);
  }

  reachGoal(target: string, params?: ReachGoalParams, callback?: () => void, ctx?: Object) {
    return this.ym('reachGoal', target, params, callback, ctx);
  }
}
