import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { md, xl } from '../../common/screen';

type CardsSliderParams = {};

/**
 *
 * @param Alpine
 */
export function productsSlider(Alpine) {
  Alpine.data('productsSlider', ({}: CardsSliderParams = {}) => ({
    swiper: null,
    init() {
      this.initSwiper();
    },
    initSwiper() {
      this.swiper = new Swiper(this.$refs.swiper, {
        modules: [Navigation],
        slidesPerView: 'auto',
        spaceBetween: 10,
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
        breakpoints: {
          [md]: {
            slidesPerView: 3,
            spaceBetween: 14,
            centerInsufficientSlides: true,
          },
          [xl]: {
            slidesPerView: 4,
            spaceBetween: 24,
            centerInsufficientSlides: true,
          },
        },
      });
    },
    load() {
      this.loaded = true;
    },
    destroy() {
      this.swiper?.destroy();
    },
  }));
}
