import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';

export function freemodeSwiper(elementOrSelector = '.js-freemode-swiper') {
  new Swiper(elementOrSelector, {
    modules: [FreeMode],
    direction: 'horizontal',
    freeMode: true,
    forceToAxis: true,
    slidesPerView: 'auto',
  });
}
