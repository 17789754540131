import Swiper from 'swiper';
import { EffectFade, Navigation, Pagination, Thumbs } from 'swiper/modules';
// @ts-ignore
import { SwiperOptions } from 'swiper/types';

export function productGallery() {
  const parent = document.querySelector('.product-gallery');
  if (!parent) return;

  const thumbnailSwiperElement = document.querySelector('.product-gallery__thumbnails-swiper');
  let thumbnailSwiper;
  if (thumbnailSwiperElement) {
    const options: SwiperOptions = {
      modules: [Navigation],
      slidesPerView: 3,
      spaceBetween: 16,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      direction: 'vertical',
      breakpoints: {},
      navigation: {
        nextEl: '.product-gallery__thumbnails-next',
        prevEl: '.product-gallery__thumbnails-prev',
      },
    };
    thumbnailSwiper = new Swiper('.product-gallery__thumbnails-swiper', options);
  }

  const options: SwiperOptions = {
    modules: [Navigation, Pagination, EffectFade, Thumbs],
    loop: true,
    effect: 'fade',
    spaceBetween: 20,
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  if (thumbnailSwiper) {
    options.thumbs = { swiper: thumbnailSwiper };
  }
  const swiper = new Swiper('.product-gallery__main-swiper', options);

  // youtube video lazy loading
  swiper.on('slideChange', swiper => {
    const slide = swiper.slides[swiper.realIndex];
    if (!slide) return;

    const lazyIframe = slide.querySelector('iframe[data-src]');
    if (!lazyIframe) return;

    lazyIframe.src = lazyIframe.dataset.src;
    lazyIframe.removeAttribute('data-src');
  });
}
