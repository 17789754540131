import { isElementVisible } from './isElementVisible';

interface Callback {
  (): void;
}

// https://stackoverflow.com/questions/152975/how-do-i-detect-a-click-outside-an-element
export class ClickedOutside {
  private readonly element: HTMLElement;
  private readonly callback: () => void;

  constructor(element: HTMLElement, callback = () => {}) {
    this.element = element;
    this.callback = callback;
  }

  private outsideClickListener = event => {
    if (!this.element.contains(event.target) && isElementVisible(this.element)) {
      // or use: event.target.closest(selector) === null
      this.callback();
    }
  };

  start = () => document.addEventListener('mousedown', this.outsideClickListener);

  stop = () => document.removeEventListener('mousedown', this.outsideClickListener);
}
