import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import { ym } from '../../lib/ym';
import { timeout } from '../../lib/timeout';

function init(swiperElement) {
  const swiper = new Swiper(swiperElement, {
    modules: [Navigation, Pagination, EffectFade, Autoplay],
    init: false,
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      init: swiper => {
        const slide = swiper.slides[0];
        if (slide && slide.dataset.swiperSlideTheme) {
          swiperElement.dataset.swiperTheme = slide.dataset.swiperSlideTheme;
        }
      },
      slideChange: swiper => {
        const slide = swiper.slides[swiper.realIndex];
        if (slide && slide.dataset.swiperSlideTheme) {
          swiperElement.dataset.swiperTheme = slide.dataset.swiperSlideTheme;
        }
      },
    },
  });
  swiper.init();

  document.querySelectorAll<HTMLLinkElement>('.main-banner__banner').forEach(element => {
    element.addEventListener('click', async e => {
      e.preventDefault();
      ym.reachGoal('topbanner');
      await timeout(300);
      window.location.href = element.href;
    });
  });
}

export function mainBanner() {
  document.querySelectorAll('.main-banner__swiper').forEach(element => init(element));
}
