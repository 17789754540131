import { ym } from '../../lib/ym';
import { timeout } from '../../lib/timeout';

export function header() {
  const mobileBar = document.querySelector('.header__mobile-bar');
  const hiddenClassName = 'is-hidden';
  const shownClassName = 'is-shown';
  const startingThreshold = 250;
  const hide = () => {
    mobileBar.classList.add(hiddenClassName);
    mobileBar.classList.remove(shownClassName);
  };
  const show = () => {
    mobileBar.classList.add(shownClassName);
    mobileBar.classList.remove(hiddenClassName);
  };

  const handleScrolling = () => {
    const position = window.scrollY;
    if (position < startingThreshold) {
      hide();
      return;
    } else {
      show();
    }
  };
  handleScrolling();
  window.addEventListener('scroll', handleScrolling);

  document.querySelectorAll('.header__im,.header__phone-text').forEach(element => {
    element.addEventListener('click', () => ym.reachGoal('tel'));
  });

  const cartLinks = document.querySelectorAll<HTMLLinkElement>('.header__basket-link');
  cartLinks.forEach(cartLink => {
    cartLink.addEventListener('click', async e => {
      e.preventDefault();
      ym.reachGoal('tocart');
      await timeout(300);
      window.location.href = cartLink.href;
    });
  });
}
