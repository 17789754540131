export type OnBxFormSubmitCallbackParams = {
  formName: string;
  formId: number;
  isSuccess: boolean;
};

export function onBxFormSubmit(selector, callback = (params: OnBxFormSubmitCallbackParams) => {}): void {
  const elements = document.querySelectorAll(selector);

  elements.forEach(element => {
    const form = element.querySelector('form[name]');
    if (!form) {
      console.warn(`onBxFormSubmit: no form inside ${selector}`);
      return;
    }

    const name = form.name;
    const id = form.elements.WEB_FORM_ID.value;

    const observer = new MutationObserver((mutationList, observer) => {
      mutationList.forEach(mutation => {
        if (mutation.type !== 'childList') return;

        if (element.querySelector('.errortext')) {
          callback({ formName: name, formId: id, isSuccess: false });
        } else if (element.querySelector('.notetext')) {
          callback({ formName: name, formId: id, isSuccess: true });
        }
      });
    });

    observer.observe(element, { childList: true, subtree: true });
  });
}
