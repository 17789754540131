import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

export function initLightGallery() {
  document.querySelectorAll<HTMLElement>('.lightgallery').forEach(gallery => {
    lightGallery(gallery, {
      plugins: [lgThumbnail],
      licenseKey: '0000-0000-000-0000',
      speed: 500,
      selector: 'a'
    });
  });
}
