import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';

import { reviewsPreview } from './blocks/reviews-preview/reviewsPreview';
import { mainBanner } from './blocks/main-banner/mainBanner';
import { Dropdown } from './blocks/dropdown/dropdown';
import { MainMenu } from './blocks/main-menu/mainMenu';
import { languageSelector } from './blocks/language-selector/languageSelector';
import { dialog } from './blocks/dialog/dialog';
import { TopSearch } from './blocks/top-search/topSearch';
import { about } from './blocks/about/about';
import { directions } from './blocks/directions/directions';
import { select } from './blocks/select/select';
import { readSmore } from './blocks/read-smore/readSmore';
import { freemodeSwiper } from './blocks/swiper/swiper';
import { productGallery } from './blocks/product-gallery/productGallery';
import { infoCards } from './blocks/info-cards/infoCards';
import { education } from './blocks/education/education';
import { initLightGallery } from './blocks/lightgallery/lightGallery';
import { compare } from './blocks/compare/compare';
import { detailsGroup } from './blocks/details/detailsGroup';
import { header } from './blocks/header/header';
import { analyticsEvents } from './lib/analyticsEvents';
import { initPlyr } from './blocks/video-player/videoPlayer';
import { productsSlider } from './blocks/products-slider/productsSlider';
import { catalog } from './blocks/catalog/catalog';

languageSelector();
new MainMenu();
new TopSearch();
document.querySelectorAll('.dropdown').forEach(dropdown => new Dropdown(dropdown));
header();
mainBanner();
reviewsPreview();
dialog('callback-dialog');
about();
select();
readSmore();
freemodeSwiper();
productGallery();
infoCards();
education();
initLightGallery();
compare();
detailsGroup('.details-group details');
analyticsEvents();
initPlyr();

Alpine.plugin(focus);
Alpine.plugin(persist);

Alpine.plugin(directions);
Alpine.plugin(productsSlider);
Alpine.plugin(catalog);
Alpine.start();

console.log('All js initialized');

export {};
