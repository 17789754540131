import TomSelect from 'tom-select';
import { TomInput } from 'tom-select/dist/types/types';

export function select(selector = '.select', settings = {}) {
  document.querySelectorAll(selector).forEach(element => {
    const select = element.querySelector('.select__select') as TomInput;
    new TomSelect(select, {
      controlInput: null,
      copyClassesToDropdown: false,
      wrapperClass: 'select__wrapper',
      controlClass: 'select__control',
      dropdownClass: 'select__dropdown',
      dropdownContentClass: 'select__dropdown-content',
      itemClass: 'select__item',
      optionClass: 'select__option',
    });
  });
}
