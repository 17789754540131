import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import ReadSmore from 'read-smore';
import { md, xl } from '../../common/screen';

export function reviewsPreview() {
  new Swiper('.reviews-preview__swiper', {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      [md]: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      [xl]: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  const texts = document.querySelectorAll('.reviews-preview__text');
  ReadSmore(texts, {
    moreText: 'Читать отзыв целиком...',
    lessText: 'Свернуть',
    blockClassName: 'reviews-preview',
  }).init();
}
