import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import { md } from '../../common/screen';

export function about() {
  new Swiper('.about__history-gallery', {
    modules: [Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      [md]: {
        enabled: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
        direction: 'vertical',
      },
    },
  });
}
