import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';
import { xl } from '../../common/screen';
import { onScrollPercentageDepth } from '../../lib/onScrollDepth';
import { ym } from '../../lib/ym';
import { onBxFormSubmit } from '../../lib/onBxFormSubmit';

export function education() {
  new Swiper('.education-certs__swiper', {
    modules: [Pagination],
    slidesPerView: 'auto',
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      [xl]: {
        enabled: false,
      },
    },
  });

  new Swiper('.education-where__swiper', {
    modules: [Pagination, Navigation],
    slidesPerView: 'auto',
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      480: {
        spaceBetween: 24,
      }
    }
  });

  if (document.querySelector('main.education')) {
    onBxFormSubmit('.education-form', ({ isSuccess }) => {
      if (isSuccess) ym.reachGoal('form_obuch');
    });

    onScrollPercentageDepth(50).then(() => ym.reachGoal('eduscroll'));
  }
}
