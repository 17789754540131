export function initPlyr(selector = '.video-player__video, .video-player video') {
  const videos = document.querySelectorAll<HTMLElement>(selector);
  if (videos) {
    import('plyr').then((Plyr) => {
      videos.forEach(video => {
        new Plyr(video);
      });
    });
  }
}
