export function onScrollPercentageDepth(threshold: number) {
  if (typeof window === 'undefined') return;

  threshold = threshold / 100;

  return new Promise((resolve) => {
    const handleScroll = () => {
      const lastPercentage = Math.min(
        1,
        (window.innerHeight + window.pageYOffset) / document.body.offsetHeight
      );

      if (lastPercentage > threshold) {
        window.removeEventListener('scroll', handleScroll);
        resolve(undefined);
      }
    };
    window.addEventListener('scroll', handleScroll);
  });
}
