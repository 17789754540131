import { ClickedOutside } from '../../lib/ClickedOutside';
import { xl } from '../../common/screen';

export class MainMenu {
  private readonly menu: HTMLElement;
  private readonly buttons: NodeListOf<HTMLElement>;
  private readonly closeButton: HTMLElement;
  private clickedOutside: ClickedOutside;

  constructor() {
    this.menu = document.querySelector('.main-menu');
    this.buttons = document.querySelectorAll<HTMLElement>('.header__menu-button');
    if (!this.menu || !this.buttons.length) return;

    this.closeButton = this.menu.querySelector('.main-menu__close');

    this.buttons.forEach(button => button.addEventListener('click', this.toggle));
    this.closeButton.addEventListener('click', this.close);

    this.clickedOutside = new ClickedOutside(this.menu, this.close);

    window
      .matchMedia(`(min-width: ${xl}px)`)
      .addEventListener('change', this.handleScreenChangeToDesktop);
  }

  handleScreenChangeToDesktop = e => {
    if (e.matches && this.isOpened()) {
      this.close();
    }
  };

  handleEscapePress = e => {
    if (e.key === 'Escape') {
      this.close();
    }
  };

  open = () => {
    this.buttons.forEach(button => button.setAttribute('aria-expanded', 'true'));
    this.menu.classList.add('is-opened');
    window.addEventListener('keydown', this.handleEscapePress);
    this.clickedOutside.start();
  };

  close = () => {
    this.buttons.forEach(button => button.setAttribute('aria-expanded', 'false'));
    this.menu.classList.remove('is-opened');
    window.removeEventListener('keydown', this.handleEscapePress);
    this.clickedOutside.stop();
  };

  isOpened = () => {
    return this.buttons[0].getAttribute('aria-expanded') === 'true';
  };

  toggle = () => {
    if (!this.isOpened()) this.open();
    else this.close();
  };
}
