import Cookies from 'js-cookie';

function googleTranslateElementInit() {
  // @ts-ignore
  new google.translate.TranslateElement(
    {
      pageLanguage: 'ru',
      // @ts-ignore
      layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      autoDisplay: false,
      gaTrack: true,
      gaId: 'UA-32435293-24',
    },
    'google_translate_element'
  );
  console.log('google loaded');
}
window.googleTranslateElementInit = googleTranslateElementInit;

function getTopDomain() {
  const parts = location.hostname.split('.');
  return parts.slice(-2).join('.');
}

export function languageSelector() {
  // Load translate API only when either cookie with language is set
  // or we have language selected in anchor e.g. /#googtrans(pl)
  if (Cookies.get('googtrans') || window.location.hash.indexOf('googtrans(') !== -1) {
    const script = document.createElement('script');
    script.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.setAttribute('defer', 'defer');
    document.body.appendChild(script);
  }

  const reset = document.querySelector('.language-selector__reset');
  if (reset) {
    reset.addEventListener('click', () => {
      Cookies.remove('googtrans');
      Cookies.remove('googtrans', { domain: '.' + getTopDomain() });
      window.location.reload();
    })
  }

  const language = document.querySelectorAll<HTMLElement>('.language-selector__language')
  language.forEach(language => {
    language.addEventListener('click', () => {
      const code = language.dataset.code;
      if (!code) return;

      Cookies.set('googtrans', '/ru/' + code);
      Cookies.set('googtrans', '/ru/' + code, { domain: '.' + getTopDomain() });
      window.location.reload();
    })
  })
}
