import { autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { ClickedOutside } from '../../lib/ClickedOutside';

export class Dropdown {
  private readonly parent: Element;
  private readonly button: HTMLElement;
  private readonly body: HTMLElement;
  private clickedOutside: ClickedOutside;
  private autoUpdateCleanup: () => void;

  constructor(container: string | Element) {
    if (container instanceof HTMLElement) {
      this.parent = container;
    } else if (typeof container === 'string') {
      this.parent = document.querySelector(container);
    }

    if (!this.parent) return;

    this.button = this.parent.querySelector('.dropdown__button');
    this.body = this.parent.querySelector('.dropdown__body');
    if (!this.button || !this.body) return;

    this.button.addEventListener('click', this.toggle);
    this.clickedOutside = new ClickedOutside(this.body, this.close);
  }

  private updateBodyPlacement = () => {
    computePosition(this.button, this.body, {
      placement: 'bottom',
      middleware: [offset(6), flip(), shift({ padding: 5 })],
    }).then(({ x, y, placement }) => {
      Object.assign(this.body.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
      this.body.dataset.dropdownPlacement = placement;
    });
  };

  private handleEscapePress = e => {
    if (e.key === 'Escape') {
      this.close();
    }
  };

  open = () => {
    this.button.setAttribute('aria-expanded', 'true');
    this.updateBodyPlacement();
    this.clickedOutside.start();
    window.addEventListener('keydown', this.handleEscapePress);
    this.autoUpdateCleanup = autoUpdate(this.button, this.body, this.updateBodyPlacement);
  };

  close = () => {
    this.button.setAttribute('aria-expanded', 'false');
    this.clickedOutside.stop();
    window.removeEventListener('keydown', this.handleEscapePress);
    if (this.autoUpdateCleanup) this.autoUpdateCleanup();
  };

  isOpened = () => this.button.getAttribute('aria-expanded') === 'true';

  toggle = () => {
    if (!this.isOpened()) {
      this.open();
    } else {
      this.close();
    }
  };
}
