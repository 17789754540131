import LazyLoad from 'vanilla-lazyload';
import { collectPropsFromElement } from './collectPropsFromElement';

interface AddToBasketResult {
  name: string;
  product_id: string;
  article: string;
  manufacturer: string;
  sections: string[];
  quantity: number;
  price: number;
  currency: string;
  price_total: number;
  quantity_total: number;
  basket_total: number;
  basket_total_formatted: string;
  basket_quantity: number;
  basket_line_html: string;
}

interface GAItem {
  item_id: string;
  item_name: string;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_variant?: string;
  item_list_name?: string;
  item_list_id?: string;
  index?: number;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  price?: number;
  currency?: string;
  quantity?: number;
}

interface GAEvent {
  items: GAItem[];
  currency?: string;
  value?: number;
}

interface GAPurchaceEvent extends GAEvent {
  transaction_id: string;
}

interface UAItem {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  coupon?: string;
  list_name?: string;
  list_position?: number;
  price?: number;
  quantity?: number;
  variant?: string;
}

function gaItemToUaItem(gaItem: GAItem): UAItem {
  let uaItem: UAItem = {
    id: gaItem.item_id,
    name: gaItem.item_name,
  };

  if (gaItem.item_brand) uaItem.brand = gaItem.item_brand;
  if (gaItem.coupon) uaItem.coupon = gaItem.coupon;
  if (gaItem.item_list_name) uaItem.list_name = gaItem.item_list_name;
  if (gaItem.index) uaItem.list_position = gaItem.index;
  if (gaItem.price) uaItem.price = gaItem.price;
  if (gaItem.quantity) uaItem.quantity = gaItem.quantity;
  if (gaItem.item_variant) uaItem.variant = gaItem.item_variant;

  let categories = [];
  if (gaItem.item_category) categories.push(gaItem.item_category);
  if (gaItem.item_category2) categories.push(gaItem.item_category2);
  if (gaItem.item_category3) categories.push(gaItem.item_category3);
  if (gaItem.item_category4) categories.push(gaItem.item_category4);
  if (gaItem.item_category5) categories.push(gaItem.item_category5);
  if (categories) uaItem.category = categories.join('/');

  return uaItem;
}

function dataLayerPush(...args) {
  window.dataLayer.push(args);
}

export function analyticsEvents() {
  // login
  BX.addCustomEvent('login-success', () => dataLayerPush('event', 'login'));

  // signup
  BX.addCustomEvent('signup-success', () => dataLayerPush('event', 'signup'));

  // view_item_list
  // only send event when list is in the view
  new LazyLoad({
    elements_selector: '.js-analytics-view-item-list',
    unobserve_entered: true,
    callback_enter: list => {
      const viewItemList = list.querySelector('script.analytics-view-item-list-event');
      if (viewItemList) {
        const gaEvent = collectPropsFromElement(viewItemList) as GAEvent;
        const uaItems = gaEvent.items.map(gaItem => gaItemToUaItem(gaItem));
        dataLayer.push({ ecommerce: { impressions: { products: uaItems } } });
      }
    },
  });
}
