function setPosition(parent, pageX) {
  const x = pageX - parent.getBoundingClientRect().left;
  const width = parent.getBoundingClientRect().width;
  const xPercent = Math.round((x / width) * 100);
  parent.style.setProperty('--position', `${xPercent}%`);
}

function init(parent) {
  parent.addEventListener('click', (e) => setPosition(parent, e.pageX));
  parent.addEventListener('mousemove', (e) => {
    if (e.buttons) {
      setPosition(parent, e.pageX);
    }
  });
}

export function compare(selector = '.compare') {
  document.querySelectorAll(selector).forEach((el) => init(el));
}
